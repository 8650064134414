import React, { FC, ReactNode } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import { useStoryblokLink } from "@app/hooks";

type Props = {
  id: string;
  children: ReactNode;
};

export const StoryblokInternalLink: FC<Props> = ({ id, children }) => {
  const { defaultLanguage, language } = useI18next();
  const internalLink = useStoryblokLink(
    id,
    language === defaultLanguage ? "default" : language,
  );

  return <Link to={internalLink ? `/${internalLink}` : ""}>{children}</Link>;
};
