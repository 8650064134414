import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" {...props}>
    <path
      fill="none"
      strokeMiterlimit={10}
      strokeWidth={2.828}
      d="M9.777 16.556 2 8.779 9.777 1"
    />
  </svg>
);

export default SvgComponent;
