import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { PostSummary } from "@app/models";
import { filterStoryblokCollectionByLanguage } from "@app/helpers";

export const useInsightsPosts = () => {
  const { language } = useI18next();
  const result = useStaticQuery(graphql`
    {
      posts: allStoryblokEntry(
        filter: { full_slug: { regex: "/insights//" } }
        sort: { field_date_string: DESC }
      ) {
        edges {
          node {
            uuid
            slug
            fullSlug: full_slug
            lang
            categoryId: field_categoryName_string
            date: field_date_string
            title: field_title_string
            image: field_image_string
            excerpt: field_excerpt_string
            isIndexingDisabled: field_isIndexingDisabled_boolean
          }
        }
      }
    }
  `);

  const posts = filterStoryblokCollectionByLanguage<PostSummary>(
    language,
    result.posts.edges,
  );

  return Object.keys(posts)
    .map((k) => {
      posts[k].fullSlug = posts[k].fullSlug.toLowerCase();

      return posts[k];
    })
    .filter((post) => !post.isIndexingDisabled);
};
