import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      d="M1.486 0v1.082h8.995L0 11.564l.765.765L11.246 1.847v8.996h1.082V0H1.486Z"
    />
  </svg>
);

export default SvgComponent;
