import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { useAppStoreIcons } from "@app/hooks/use-app-store-icons/useAppStoreIcons";
import { useSiteData } from "@app/hooks";

export const AppStoreLinks: React.FC = () => {
  const siteData = useSiteData();
  const { appStore, playMarket } = useAppStoreIcons();
  const appStoreIcon = getImage(appStore);
  const playMarketIcon = getImage(playMarket);

  return (
    <div
      className={classNames(
        "align-center mb-10 flex flex-wrap gap-3",
        "lg:mb-15 lg:gap-x-5",
      )}
    >
      <a href={siteData.iosLink} target="_blank" rel="noopener noreferrer">
        <GatsbyImage
          image={appStoreIcon}
          className="w-[135px] md:w-[150px]"
          alt="Apple Store"
        />
      </a>

      <a href={siteData.androidLink} target="_blank" rel="noopener noreferrer">
        <GatsbyImage
          image={playMarketIcon}
          className="w-[153px] md:w-[170px]"
          alt="Google Play"
        />
      </a>
    </div>
  );
};
