import { useStaticQuery, graphql } from "gatsby";

export const useAppStoreIcons = () => {
  const { appStore, playMarket } = useStaticQuery(graphql`
    {
      playMarket: file(name: { eq: "google_play" }, extension: { eq: "png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      appStore: file(name: { eq: "app_store" }, extension: { eq: "png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  return {
    appStore,
    playMarket,
  };
};
