import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" {...props}>
    <path
      fill="none"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.391}
      d="M.696 8.195h15M7.883.696v15"
    />
  </svg>
);

export default SvgComponent;
